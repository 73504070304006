<template>
    <div>
        <CompTable ref="comp_table" :title="title" :columns="columns" :table-api="'/gateway/api/fastDev/AppTemplate/findAppAuthByTemplateId?orgCode=&templateId=' + $core.getUrlParam('templateId')" :dataBefore="onDataBefore">
            <template #search="evt">
                <Input v-model="evt.search.keyword" placeholder="请输入搜索内容"></Input>
            </template>
        </CompTable>
    </div>
</template>

<script>
import Route from "../communitymobilization/entity/Route"
import CompTable from "../jointly/components/comp-table.vue"

export default {
    components: { CompTable },

    data() {
        return {
            title: this.$core.getUrlParam("title"),

            columns: [
                {
                    title: "机构名称",
                    key: "orgName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "机构编码",
                    key: "orgCode",
                    minWidth: 150,
                    align: "center",
                },
                {
                    float: "right",
                    title: "操作",
                    width: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            Route.jump(`/residentdatabasedatatable?id=${params.row.appId}&title=${this.title}&d=false&orgCode=${params.row.orgCode}&s=collect&type=DYTX&ti=${params.row.templateId}`)
                                        },
                                    },
                                },
                                "明细"
                            ),
                        ])
                    },
                },
            ],

            // orgCode 对象
            orgCodeObj: null,
        }
    },

    methods: {
        onDataBefore(data) {
            this.$refs.comp_table.keyword(data.keyword)
            return false
        },
    },
}
</script>

<style lang="less" scope>
.select-org {
    cursor: pointer;
    padding: 0 10px;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    width: 250px !important;
    height: 32px;

    .value {
        width: 100%;
        line-height: 32px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .ph {
        color: #999;
    }
}
</style>
